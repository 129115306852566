<template>
  {{ dataSource }}
  <div
    v-if="node && !editMode"
    :style="node.style"
    :class="[
      node.class,
      'tk-node',
      editing ? 'edit-mode' : null,
      doEditThis ? 'selected' : null,
    ]"
    @click.stop="selectNode(node)"
    :id="node.id"
  >
    <span
      v-if="
        node.id != 'root' &&
        node.content &&
        (!node.children || node.children.length === 0)
      "
      v-show="hovering"
      class="material-icons btn-edit"
      style="float: right"
      @click.stop="doEdit(node)"
    >
      create
    </span>

    <div
      v-if="html && !node.component"
      v-html="html"
      :class="[node.class]"
      v-for="(ds, i) in innerData"
      :key="i"
    ></div>
    <component
      v-if="node.component"
      :is="node.component"
      :data="node"
      :dataSource="ds"
      v-for="(ds, i) in innerData"
      :key="i"
    ></component>
    <!-- <component :is="contact"></component> -->
    <!-- :id="widget.id"
      :class="widget.class"
      :style="widget.style" -->
    <Widget
      :node="n"
      :data="data"
      :dataSource="dataSource"
      v-for="(n, i) in node.children"
      :key="`wc-${i}`"
    ></Widget>
  </div>
  <div
    v-if="
      editMode && $store.state.currentItem && $store.state.editId == node.id
    "
  >
    <textarea
      v-model="$store.state.currentItem.content"
      id="textEdit"
      @blur="doneEdit"
      @keyup="doKeyPress"
    ></textarea>
  </div>
</template>

<script>
// import Widget from './Widget.vue'
// var MarkdownIt = require("markdown-it");

import Mustache from "mustache";
import Contact from "./Contact.vue";
import EditorJs from "./EditorJs";
import SimpleText from "./SimpleText";
import H1 from "./H1.vue";

var md = require("markdown-it")({
  html: false,
  linkify: true,
  typographer: true,
});

export default {
  name: "node",
  props: ["node", "data", "dataSource"],
  components: {
    Contact,
    EditorJs,
    SimpleText,
    H1,
  },
  data: function () {
    return {
      editMode: false,
      hovering: false,
    };
  },
  methods: {
    doEdit(node) {
      if (!this.editMode) {
        this.$store.commit("editChild", { child: node });
        setTimeout(() => {
          document.getElementById("textEdit").focus();
        }, 100);
        this.editMode = true;
      }
    },
    doneEdit() {
      this.$store.commit("editChild", { child: null });
      this.editMode = false;
    },
    selectNode(node) {
      // this.$store.commit("editChild", { child: null });
      this.$store.commit("selectChild", { child: node });
    },

    doKeyPress(e) {
      // console.log(e);
      if (e.code === "Enter" && !e.shiftKey) {
        this.doneEdit();
        e.preventDefault();
        return false;
      }
    },
  },
  computed: {
    innerData: function () {
      if (!this.node.dataSource) return [this.$store.state.docData];

      const ds = this.$store.state.docData[this.node.dataSource];

      console.log("DS ", ds);
      if (Array.isArray(ds)) return ds;
      return [this.$store.state.docData];
    },

    html: function () {
      var out = "";
      // console.log(this.$store.state.docData)
      if (this.node.html) {
        try {
          out += Mustache.render(this.node.html, this.innerData);
        } catch (error) {
          out += this.node.html;
        }
      } else if (this.node.content) {
        try {
          out += md.render(Mustache.render(this.node.content, this.innerData));
        } catch (error) {
          // console.log(error)
          out += md.render(this.node.content);
        }
      }
      return out;
    },

    editing() {
      return this.$store.state.editMode;
    },
    doEditThis() {
      if (!this.$store.state.currentItem) return;
      return this.doEdit && this.$store.state.currentItem.id === this.node.id;
    },
  },
};
</script>

<style lang="scss" scoped>
#textEdit {
  width: 100%;
  height: 25vh;
}
.btn-edit {
  cursor: pointer;
  position: absolute;

  background-color: black;
  color: white;
  padding: 0.5em;
  border-radius: 20%;
}
.tk-node {
  &.edit-mode {
    border: 2px solid transparent;
    cursor: pointer;

    &:hover {
      border: 2px dashed rgba(192, 192, 192, 0.4);
    }
    .selected {
      border: 2px dashed silver;
      padding: 0.1em;
    }
  }
}
</style>