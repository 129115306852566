import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import Vue from 'vue'
import Contact from './components/Contact.vue'


// import ElementUI from 'element-ui'
// import locale from 'element-ui/lib/locale/lang/en'

// import Editor from 'vue-editor-js'
// import VueCompositionApi from '@vue/composition-api'

const app = createApp(App)
// app.component('w1', Contact)
app.use(store).mount('#app')
// app.use(VueCompositionApi)
// app.use(Editor)

// console.log(Vue.render(app))

// app.component('html-fragment', {
//     functional: true,
//     props: ['html'],
//     render(h, ctx) {
//       const nodes = new Vue({
//         beforeCreate() { this.$createElement = h }, // not necessary, but cleaner imho
//         template: `<div>${ctx.props.html}</div>`
//       }).$mount()._vnode.children
//       return nodes
//     }
//   })
