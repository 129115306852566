<template>
  <div class="doctree" v-if="myList[0] && myList[0].children">
    <doc-tree-item :node="myList" />
  </div>
  <div class="data" @click="addDataSource()">
    <div class="material-icons">storage</div>
    <div class="title">Data Source</div>
  </div>
  <div class="">
    <input v-if="$store.state.currentItem" type="text" v-model="$store.state.currentItem.dataSource" />
  </div>
</template>

<script>
import DocTreeItem from "./DocTreeItem.vue";
import draggable from "vuedraggable";
export default {
  components: { DocTreeItem, draggable },
  computed: {
    myList: {
      get() {
        return [this.$store.getters.items];
      },
      set(value) {
        this.$store.commit("setPage", { dItems: value[0] });
      },
    },
  },

  methods:{
    addDataSource(){
      var url = window.prompt("URL to data source", "https://happycoding.io/tutorials/javascript/example-ajax-files/random-welcomes.json")
      this.$store.dispatch('loadDataSource', {url: url})
    }
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
.doctree, .data {
  color: white;
  text-align: left;
  justify-content: space-between;
}

.data{
  color:white;
  display: flex;
  background-color: #222;
  padding:0.5em;
  margin:0.5em;
  &:hover{
    background-color:#444;
    cursor: pointer;
  }
  div{
    flex:auto 1 0;
    
    &.material-icons{
      flex: 30px 0 0 ;
    
    }
    &.title{
      padding-left:1em;
    }
  }
}
</style>