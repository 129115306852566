<template>
  <editor ref="editor" :config="config" :initialized="onInitialized" />
  <!-- <editor ref="editor" /> -->
</template>

<script>
import Header from "@editorjs/header";
import List from "@editorjs/list";
import CodeTool from "@editorjs/code";
// import Paragraph from "@editorjs/paragraph";
import Embed from "@editorjs/embed";
// import Table from "@editorjs/table";
// import Checklist from "@editorjs/checklist";
// import Marker from "@editorjs/marker";
// import Warning from "@editorjs/warning";
// import RawTool from "@editorjs/raw";
import Quote from "@editorjs/quote";
// import InlineCode from "@editorjs/inline-code";
// import Delimiter from "@editorjs/delimiter";
import SimpleImage from "@editorjs/simple-image";
import ImageTool from "@editorjs/image";

import { Editor } from "../vue-editor-js/src";

// import { Editor } from "../../node_modules/vue-editor-js/src/Editor";

export default {
  components: {
    Editor,
  },
  methods: {
    onInitialized(editor) {
      console.log(editor);
    },
  },
  data() {
    return {
      config: {
        tools: {
          list: { class: List, inlineToolbar: true },
          image: SimpleImage,
          // embed: {
          //   class: Embed,
          // },
          header: {
            class: Header,
            config: {
              placeholder: "Enter a header",
              levels: [1, 2, 3, 4],
              defaultLevel: 3,
            },
          },
          // image: {
          //   class: ImageTool,
          //   config: {
          //     endpoints: {
          //       byFile: "http://localhost:5001/uploadFile", // Your backend file uploader endpoint
          //       byUrl: "http://localhost:5001/fetchUrl", // Your endpoint that provides uploading by Url
          //     },
          //   },
          // },
          code: {
            class: CodeTool,
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
            shortcut: "CMD+SHIFT+O",
            config: {
              quotePlaceholder: "Enter a quote",
              captionPlaceholder: "Quote's author",
            },
          },
        },

        // onReady: () => {
        //   console.log("on ready");
        // },
        // onChange: (args) => {
        //   console.log("Now I know that Editor's content changed!");
        // },
        data: {
          time: 1591362820044,
          blocks: [
            {
              type: "header",
              data: {
                text: "Editor.js",
                level: 2,
              },
            },
            {
              type: "paragraph",
              data: {
                text:
                  "Hey. Meet the new Editor. On this page you can see it in action — try to edit this text.",
              },
            },

          ],
          version: "2.18.0",
        },
      },
    };
  },
};
</script>

<style>
</style>