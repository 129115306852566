<template>
  <div v-if="$store.getters.items" >
    <div class="inner">

    
    <Widget
      :node="$store.getters.items"
      :data="$store.state.docData"
    ></Widget>
        <no-page v-if="!$store.getters.items.children" class="start">
        </no-page>          
        
      <span class="material-icons" style="float:right" @click.stop="addChild">
        add
      </span>
      <div style="clear:both"></div>
      
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;
import Widget from "./Widget";
import NoPage from "./NoPage";

// import YAML from "yaml";

// import Mustache from "mustache";
import store from '../store';

function addCSSRule(sheet, selector, rules, index) {
  sheet.addRule(selector, rules, index);
}

export default {
  components: {
    Widget,
    NoPage
  },
  data: function () {
    return {
      
    };
  },
  methods:{
    addChild(){
      this.$store.commit('newChild', {parent: null})
    }
  },
  computed:{
    style: function(){
      return this.$store.getters.style
    }
  },
  watch: {
    style: function(val){
      // console.log(val)
      this.ss = document.getElementById('style')
      // this.ss = document.createElement("style");
      this.ss.type = "text/css";
      this.ss.innerHTML = val;
      // this.ss.appendChild(document.createTextNode(""));
      // window.document.head.appendChild(val);

    }
    // 'this.$store.state.docStyle': function () {
      // var out = "";
      // for (var key in this.$store.state.docStyle) {
      //   var val = this.$store.state.docStyle[key];
      //   var attrs = "";

      //   for (var item of val) {
      //     for (var k in item) {
      //       console.log(k);
      //       const value = Mustache.render(item[k], this.$store.state.docData);
      //       attrs += `${k}: ${value}; \n`;

      //       console.log(this.ss, `.${key}`, `${k}: ${value}`);
      //     }
      //   }

      //   out += `${key}{
      //     ${attrs}
      //   }`;
      // }

      // // return out
      // // Create the <style> tag
      // this.ss = document.createElement("style");
      // this.ss.type = "text/css";
      // this.ss.innerHTML = out;
      // // this.ss.appendChild(document.createTextNode(""));
      // document.head.appendChild(this.ss);
    // },
  },
  mounted() {
    
  },
};
</script>

<style lang="scss">
@import "../scss/index.scss";




</style>
