import { createStore } from 'vuex'
import YAML from "yaml";

import Mustache from "mustache"
import { findFirst, findAll, findAndDeleteFirst } from 'obj-traverse/lib/obj-traverse'
import { v4 as uuid } from 'uuid'
const arrayMove = require('array-move')
var md = require("markdown-it")({
  html: false,
  linkify: true,
  typographer: true,
});
const extractTag = function (totalText, tagName) {
  var startPos = totalText.indexOf(`<${tagName}>`) + `<${tagName}>`.length;
  var endPos = totalText.indexOf(`</${tagName}>`);
  var targetText = totalText.substring(startPos, endPos).trim();
  var remainingText = totalText.substring(0, startPos).trim() + totalText.substring(endPos).trim();
  return { extracted: targetText, remaining: remainingText }
}
function getParentId(array, id, parentId) {
  return array.some(o =>
    o.id === id ||
    o.children && (parentId = getParentId(o.children, id, o.id)) !== null
  )
    ? parentId
    : null;
}
function getParent(array, childId){
  const parentId = getParentId(array.children, childId)
          if (parentId)
            parent = findFirst(array, 'children', { id: parentId })
          else
            parent = array
  return parent
}

function populateIdIndex(nodes){
  var idIndex = []
  var doP = function(nodes, ids){
    if(!nodes.children) return false
    for (var node of nodes.children){
      
      if (ids.includes(node.id))
        node.id = uuid()
      ids.push(node.id)
      doP(node, ids)
    }
  }
  idIndex = []
  doP(nodes, idIndex)
  return idIndex
}

function ensureUniqueIds(node, allIds){
  if (allIds.includes(node.id))
    var i =1
    var id = node.id
    while(allIds.includes(id)){
      id = `${node.id}-${++i}`
    } 
    node.id = id
    if (node.children && node.children.length > 0){
      for (var child of node.children)
      {
        ensureUniqueIds(child, allIds)
      }
    }
}

//  const findParent = function(tree, child){

//   for (item of tree.children){
//     console.log(item)
//     if (item === child) { console.log("HERE!")}
//     findParent(item, child)
//   }
// }

export default createStore({
  state: {
    docItems: {id:'root'},
    docStyle: null,
    docData: {"message": "Hello World!"},
    dirty: false,
    currentItem: null,
    editId: null,
    closed: [],

  },
  mutations: {
    setPage(state, payLoad) {
      // this.$set(state, 'docItems', payLoad.document)
      if (payLoad.dItems) state.docItems = payLoad.dItems
      if (payLoad.dStyle) state.docStyle = payLoad.dStyle
      if (payLoad.dData) state.docData = payLoad.dData
      // console.log(payLoad)
    },
    newPage(state){
      state.docItems = {id: 'root', children: []}
      state.docStyle = {}
      state.docData = {}
      state.currentItem = null
      state.editId = null
      state.closed = []
      state.dirty = true
    },
    newChild(state) {
      const newChild = {
        id: uuid()
      }
      var parent = state.docItems

      if (state.currentItem){  // If something selected
        if(state.currentItem.children)
          parent = state.currentItem
        else{   // try parent
          parent = getParent(state.docItems, state.currentItem.id)
        }
      }

      if(parent._template){
        parent = getParent(state.docItems, parent.id) || 'root'
        console.log(parent)
      }

      if (parent.children) {
        parent.children.push(newChild);
      } else {
        parent.children = [newChild];
      }

      state.dirty = true
      state.currentItem = newChild
      state.editId = null;

      
      
    },
    addChild(state, { parent, child, noSelect }) {

      child.id = uuid()

      if (parent.children) {
        parent.children.push(child)
      } else {
        parent.children = [child]
      }
      if (!noSelect)
        state.currentItem = child
      state.closed[parent.id] = false
      state.dirty = true

    },
    duplicate(state, { child }) {
      const parentId = getParentId(state.docItems.children, child.id) || 'root'
      const parent = findFirst(state.docItems, 'children', { id: parentId })
      const newChild = JSON.parse(JSON.stringify(child))
      // newChild.id = uuid()
      
      const allIds = populateIdIndex(state.docItems)
      ensureUniqueIds(newChild, allIds)

      parent.children.push(newChild)
      const index1 = parent.children.indexOf(newChild)
      const index2 = parent.children.indexOf(child) + 1
      parent.children = arrayMove(parent.children, index1, index2)

    },
    moveUp(state, { child }) {
      const parentId = getParentId(state.docItems.children, child.id) || 'root'
      const parent = findFirst(state.docItems, 'children', { id: parentId })
      const index = parent.children.indexOf(child)
      parent.children = arrayMove(parent.children, index, index - 1)
    },
    moveDown(state, { child }) {
      const parentId = getParentId(state.docItems.children, child.id) || 'root'
      const parent = findFirst(state.docItems, 'children', { id: parentId })
      const index = parent.children.indexOf(child)
      parent.children = arrayMove(parent.children, index, index + 1)
    },

    removeChild(state, { child }) {
      findAndDeleteFirst(state.docItems, 'children', { id: child.id })
      state.dirty = true
      if (state.currentItem === child){
        state.currentItem = null
        state.editId = null
      }
    },
    selectChild(state, { child }) {
      state.editId = null
      state.currentItem = child
      if(child)
        state.closed[child.id] = !(state.currentItem === child && child.children && child.children.length > 0 && state.closed[child.id])
    },
    setChild(state, {data}){
      // const child = findFirst(state.docItems, 'children', { id: state.currentItem.id })
      // state.currentItem = { ...state.currentItem, ...data}
      
      console.log(data)
      const allIds = populateIdIndex(state.docItems)
      ensureUniqueIds(data, allIds)

      for (var prop in data)
      {
        state.currentItem[prop] = data[prop]
        
        console.log(prop)
      }
      // if (data.children && data.children.length > 0)
      //   state.currentItem = state.currentItem.children[0]
      state.dirty = true
    },
    openChild(state, { child }) {
      state.closed[child.id] = false
    },
    closeChild(state, { child }) {
      state.closed[child.id] = true
    },
    moreOptions(state, { child }) {
      if (child.id === 'root') return
      if (state.moreOptions === child.id)
        state.moreOptions = null
      else
        state.moreOptions = child.id
    },
    editChild(state, { child }) {

      state.currentItem = child
      if (child)
        state.editId = child.id ? child.id : null

    },
    noEdit(state){
      state.currentItem = null
      state.editId = null
    },

    convertToHTML(state) {

      if (!state.currentItem.html)
        state.currentItem.html = ""

      if (!state.currentItem.html && state.currentItem.content) {
        state.currentItem.html = md.render(state.currentItem.content)

        // delete state.currentItem["content"];
        delete state.currentItem["component"];
      }
    },
    convertToContent(state) {
      // if (!state.currentItem.content && !state.currentItem.html){
      //   state.currentItem.content = ""
      //   delete state.currentItem["html"];

      // }
      if (!state.currentItem.content)
        state.currentItem.content = ""
      delete state.currentItem["html"];
      delete state.currentItem["component"];

    },
    convertToComponent(state, { component }) {
      const child = state.currentItem
      console.log(component)
      child.component = component
      child.html = null
      child.content = null
    },
    convertFlow(state, { type }) {
      const child = state.currentItem
      if (type === 'column')
        child.style = "display: flex; flex-direction: row"
      else if (type === 'row')
        child.style = "display: flex; flex-direction: column"
      else if (type === 'grid')
        child.style =
          `display: grid;
grid-template-columns: 1fr 1fr 1fr;
`
    }
  },
  actions: {
    loadPage({ commit }) {
      fetch('page.tkdoc')
        .then((response) => response.text())
        .then((data) => {
          var doc = extractTag(data, "doctree")
          const docIn = doc.extracted

          const styleIn = extractTag(doc.remaining, "css").extracted

          const dataIn = extractTag(doc.remaining, "data").extracted



          const dItems = YAML.parseDocument(docIn).toJS()
          const dStyle = YAML.parseDocument(styleIn).toJS()
          const dData = YAML.parseDocument(dataIn).toJS()

          // const doc = YAML.parseAllDocuments(data);
          // const dItems = [doc[0].toJS()];
          // const dStyle = doc[1].toJS().styles;
          // const dData = doc[2].toJS().data;
          console.log(dItems)
          if (dItems)
            commit('setPage', { dItems: dItems, dStyle, dData })
        })
        .catch((e) => {
          console.log(e)
        })
    },
    loadPage2({ commit }) {
      fetch('/page.yaml')
        .then((response) => response.text())
        .then((data) => {

          const doc = YAML.parseAllDocuments(data);
          const dItems = [doc[0].toJS()];
          const dStyle = doc[1].toJS().styles;
          const dData = doc[2].toJS().data;
          commit('setPage', { dItems: doc[0].toJS(), dStyle, dData: dData })
        });
    },
    savePage({ state }) {
      // console.log(JSON.stringify(state.docItems))
      const jPage = {
        'doctree': state.docItems,
        'css': state.docStyle,
        'data': state.docData
      }
      console.log(JSON.stringify(jPage))


      const doc =
        `<doctree>
---
${YAML.stringify(state.docItems)}
</doctree>
<css>
---
${YAML.stringify(state.docStyle)}
</css>
<data>
---
${YAML.stringify(state.docData)}
</data>`
      console.log(doc)
    },
    loadDataSource({state}, {url}){
      alert(url)
      fetch(url)
      .then((response) => response.json())
      .then((data) => {
        state.docData = data
        console.log(data)
      })
    }
  },
  getters: {
    items(state) {
      return state.docItems
    },
    style(state) {


      var out = "";
      for (var key in state.docStyle) {
        var val = state.docStyle[key];
        var attrs = "";

        for (var item of val) {
          for (var k in item) {

            var value
            try {
              value = Mustache.render(item[k], state.docData);
            } catch (error) {
              value = item[k]
            }
            attrs += `    ${k}: ${value}; \n`;

            // console.log(this.ss, `.${key}`, `${k}: ${value}`);
          }
        }

        out += `${key}{\n${attrs}\n} \n`;
      }

      // return out
      // Create the <style> tag
      var ss = document.createElement("style");
      ss.type = "text/css";
      ss.innerHTML = out;
      // this.ss.appendChild(document.createTextNode(""));
      document.head.appendChild(ss);
      return out
    },
    showSelector(state){
      if (!state.currentItem) return false// Nothing selected
      if (state.currentItem.id === "root") return false// Root Selected
      if (state.editId !== null) return false
      if ((state.currentItem.children ))
        return false
      if (state.currentItem.html != null) return false
      if (state.currentItem.content != null) return false
      if (state.currentItem.component != null) return false
      return true
    }
  },
  modules: {
  }
})
