<template>
  <div class="selector">
    
    
      <div class="icon" @click="$store.commit('convertFlow', {type:'column'})">
        <div class="material-icons">view_column</div>
        <div class="title">Flex: Columns</div>
      </div>
      <div class="icon" @click="$store.commit('convertFlow', {type:'row'})">
        <div class="material-icons">table_rows</div>
        <div class="title">Flex: Rows</div>
      </div>

      <div class="icon"  @click="$store.commit('convertFlow', {type:'grid'})">
        <div class="material-icons">grid_view</div>
        <div class="title">Grid</div>
      </div>

    </div>
  
</template>

<script>
export default {

    methods:{
        makeFolder(){
             this.$store.commit("addChild", { parent: this.$store.state.currentItem, child: {}, noSelect:true })
        }
    }
};
</script>

<style lang="scss" scoped>
.selector {
  background-color: darkgray;
  display:flex;
  flex-direction: row;
  
  .icon{
        flex: auto 1 1;
        border: 1px solid rgb(85, 85, 85);
  }
  .material-icons {
      
    font-size: 300%;
    padding: 0.25em;
    border: 1px solid rgb(85, 85, 85);;
    border-radius: 10px;
    margin: 0.25em;
    background-color: rgb(85, 85, 85);;
    color: white;
    cursor: pointer;
    &:hover {
      background-color: white;
      color: rgb(85, 85, 85);;
    }
  }
}
</style>