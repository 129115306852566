<template>
  <draggable
    :list="node"
    :group="id"
    tag="div"
    item-key="id"
    ghost-class="ghost"
    @move="onMove"
  >
    <template #item="{ element }">
      <div class="node">
        <div
          class="inner"
          @click="select(element)"
          :class="
            $store.state.currentItem
              ? $store.state.currentItem.id === element.id
                ? 'selected'
                : ''
              : ''
          "
        >
          <!-- <div class="material-icons">drag_handle</div> -->
          <span
            class="material-icons"
            @click.stop="open(element)"
            v-if="(!element.children || element.children.length===0) && element.content != null && !element.html"
          >
            subject
          </span>
          <span
            class="material-icons"
            @click.stop="open(element)"
            v-if="(!element.children || element.children.length===0) && element.html != null"
          >
            code
          </span>
          <span
            class="material-icons"
            @click.stop="open(element)"
            v-if="(!element.children || element.children.length===0) && element.component"
          >
            widgets
          </span>
          <span
            class="material-icons"
            @click.stop="open(element)"
            v-if="
              element.children &&
              element.children.length > 0 &&
              $store.state.closed[element.id] &&
              element.id != 'root'
            "
          >
            keyboard_arrow_right
          </span>
          <span
            class="material-icons"
            @click.stop="close(element)"
            v-if="
              element.children &&
              element.children.length > 0 &&
              !$store.state.closed[element.id] &&
              element.id != 'root'
            "
          >
            expand_more
          </span>
          <div class="title">{{ element.id }}</div>
          <div class="buttons">
            <span class="material-icons" @click.stop="add(element)" v-if="element.children">
              add_circle
            </span>
            <span class="material-icons" @click.stop="showMore(element)">
            more_horiz
            </span>

          </div>
        </div>
        <div v-if="$store.state.moreOptions === element.id" class="moreOptions">
            <span class="material-icons" @click.stop="duplicate(element)">
              content_copy
            </span>
            <span class="material-icons" @click.stop="moveUp(element)">
              arrow_upward
            </span>
            <span class="material-icons" @click.stop="moveDown(element)">
              arrow_downward
            </span>
            <span class="material-icons" @click.stop="remove(element)">
              delete_forever
            </span>
        </div>
        <doc-tree-item
          :node="element.children"
          v-show="!$store.state.closed[element.id] || element.id === 'root'"
        />
      </div>
    </template>
    <template #header> </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: { draggable },
  props: ["id", "node", "data"],
  data: function(){
    return {
      
    }
  },
  methods: {
    add(el) {
      const newNode = {
        id: "test",
        

      };
      this.$store.commit("addChild", { parent: el, child: newNode });
      setTimeout(function () {
        document.getElementById("nodeTitle").focus();
        document.getElementById("nodeTitle").select();
      }, 100);
    },
    remove(el) {
      this.$store.commit("removeChild", { child: el });
    },
    select(el) {
      this.$store.commit("selectChild", { child: el });
    },
    open(el) {
      this.$store.commit("openChild", { child: el });
    },

    close(el) {
      this.$store.commit("closeChild", { child: el });
    },
    showMore(el){
      
      this.$store.commit("moreOptions", { child: el });
    },
    duplicate(el){
      this.$store.commit("duplicate", { child: el });
      },
      moveUp(el){
        this.$store.commit("moveUp", {child: el})
      },
      moveDown(el){
        this.$store.commit("moveDown", {child: el})
      },
    onMove(evt, origonalEvent) {
      console.log(evt);
      console.log(origonalEvent);
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  margin: 0;
  padding: 0;
  padding-right: 1em;
  text-align: right;

  flex: 90px 0 0;
  .material-icons {
    margin: 0;
    padding: 0;
    color: grey;
    cursor: pointer;
    &:hover {
      color: white;
    }
  }
}

.doctree .node {
  font-size: 95%;
  &:last-child {
    margin-bottom: 6px;
  }
  text-align: left;

  list-style: none;
  margin: 0;
  padding: 0;
  border-left: 5px solid rgb(26, 26, 26);
  border-bottom: 1px solid rgb(53, 53, 53);

  padding: 0.2em;
  padding-right: 0;
  margin-left: 0.4em;
  color: silver;
  cursor: pointer;

  .inner {
    border: 4px solid transparent;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      background-color: rgb(61, 61, 61);
      border-bottom: 4px solid rgb(32, 32, 32);
    }

    display: flex;
    justify-content: space-between;
    width: 100%;
    .title {
      flex: auto 1 1;
      padding-left: 0.5em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.selected {
  background-color: #161616;
  color: white;
}
.ghost {
  .inner {
    background-color: #222;
    color: #666;
  }
}
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
.moreOptions{
  text-align: right;
  padding:0.5em 3em 0  0 ;
}
</style>