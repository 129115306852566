<template>
  <div v-if="!$store.state.editMode" v-html="content"></div>
  <div v-show="$store.state.editMode">
    <!-- <input type="text" v-model="data.content" /> -->
    <div :key="`editor-${data.id}`" :ref="`editor-${data.id}`" contenteditable="true" @input="onInput" @blur="onBlur" @focus="onFocus"> </div>
  </div>

</template>

<script>
import Mustache from "mustache"

export default{
    props: ['data', 'dataSource'],
    watch:{
        editMode:function(v){
            if (v){
            this.$refs[`editor-${this.data.id}`].innerHTML = this.content
            this.$refs[`editor-${this.data.id}`].focus()
            }
        }
    },
    mounted(){
        this.$refs[`editor-${this.data.id}`].innerHTML = this.content
        this.$refs[`editor-${this.data.id}`].focus()
    },
    activated(){
        
        },
    updated(){
        this.$refs[`editor-${this.data.id}`].innerHTML = this.content
        
    },
    methods:{
        onInput(e)
        {
            const content = e.target.innerHTML
            this.data.content = content
        },
        onBlur(e){
            this.$refs[`editor-${this.data.id}`].innerHTML = this.content 
        },
        onFocus(e){
            console.log(this.dataSource)
            this.$refs[`editor-${this.data.id}`].innerHTML = this.data.content || ''
        },
    },
    computed:{
        editMode(){
            return this.$store.state.editMode
        },
        content(){
            
            return Mustache.render(this.data.content, this.dataSource)
        }
    }
}
</script>

<style lang="scss" scoped>
input{
    width:100%;
    border: none;
    :focus{
        outline: none;
    }
}
</style>