<template>
  <div class="selector" @keydown="key" v-if="showSelector" >
    <input type="text"
     ref="search"
      placeholder="Component Search" :key="`search-${$store.state.currentItem.id}`" v-model="search" id="component-search" autocomplete="off" />
    
    <div
      class="item"
      
      v-for="(component, i) in componentList"
      :key="`component-${$store.state.currentItem.id}-${i}`"
      :class="i === selectedIndex ? 'selected' : ''"
      @click="selectComponent(component)"
    >
      <div class="icon material-icons">
        {{ component.icon }}
      </div>
      <div class="title">
        {{ component.title }}
      </div>
    </div>
    
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
export default {

  data() {
    return {
      showWidgets: false,
      selectedIndex: 0,
      search: "",
      _keyListner: null,

      components: [
        {
          title: "Text (Simple)",
          data: { id:'content', content: "", component:"simple-text" },
          icon: "title",
          search: 'p div <p <div text txt'
        },
        {
          title: "Heading 1",
          data: { id:'heading', component:"h1"},
          icon: "title",
          search: 'h1 <h1'
        },
        // {
          //   title: "Heading 1 (Markdown)",
        //   data: { id:'heading', content: "# Heading" },
        //   icon: "article",
        //   search: 'h1 <h1'
        // },
        // {
          //   title: "Heading 2 (Markdown)",
        //   data: { id:'heading', content: "## Heading" },
        //   icon: "article",
        //   search: 'h2 <h2'
        // },
        {
          title: "Image",
          data: { id:'image', content: "![Stock Image](https://picsum.photos/1200/400)", class: "tk-image" },
          icon: "image",
          
          search: 'img <img'
        },
          {
            title: "Document (Markdown)",
            data: { id:'content', content: "" },
            icon: "article",
            search: 'p div <p <div doc content txt'
          },
        {
          title: "Document (EditorJS)",
          data: { id:'content', component: "editor-js", blocks: [] },
          icon: "article",
          search: 'txt doc content'
        },
        {
          title: "HTML",
          data: { id:'code', html: "" },
          icon: "code",
          search: "<html"
        },
        {
          title: "Container Rows",
          data: { id:'container', children: [], class:"tk-container-row"},
          icon: "table_rows",
          search: 'flex row <flex'
        },
        {
          title: "Container Column",
          data: { id:'container', children: [], class:"tk-container-column" },
          icon: "view_column",
          search: 'flex column <flex'
        },
        {
          title: "Container Grid",
          data: { id:'container', children: [], class:"tk-container-grid"},
          icon: "grid_view",
          search: 'grid <grid'
        },
        {
          title: "Contact Form",
          data: { id:'contact', component: "contact" },
          icon: "email",
          search: 'contact email @'
        },
        {
          title: "Template: Hero Section",
          data: { 
            id:'hero-section',
            
            children: [
              {"id":"hero-title",
              "component": "h1",
              "content":
              "My Awesome Page"}
              ,
              {"id":"hero-subtitle",
              "content":"It really is awesome",
              "component": "simple-text"
              }
            ],
            class:"tk-container-row tk-hero",
            _template: true,
          },
          icon: "view_compact",
        },
        {
          title:"Carosel (bootstrap)",
          icon:"view_compact",
          data:{
            id: 'carosel',
            
            html: `<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="https://picsum.photos/1200/400" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="https://picsum.photos/1200/400?2" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="https://picsum.photos/1200/400?3" class="d-block w-100" alt="...">
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"  data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"  data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>`
          }
        },
        {
          title:"Card (bootstrap)",
          icon:"view_compact",
          data:{
            id: 'card',
            
            html: `<div class="card" style="width: 18rem;">
  <img src="https://picsum.photos/600/300" class="card-img-top" alt="...">
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-primary">Go somewhere</a>
  </div>
</div>`
          }
        },
        {
          title:"Navbar (bootstrap)",
          icon:"view_compact",
          data:{
            id: 'navbar',
            
            html: `<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Navbar</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
        </li>
      </ul>
      <form class="d-flex">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form>
    </div>
  </div>
</nav>`
          }
        },
      ],
    };
  },
  computed: {
    showSelector: function(){
      return this.$store.getters.showSelector
      
    },
    
    componentList: function () {
      return this.components.filter((component) => {
        return (
          component.title.toLowerCase().indexOf(this.search.toLowerCase()) !==
          -1 ||
          (component.search && component.search.toLowerCase().indexOf(this.search.toLowerCase()) !==
          -1)
        );
      });
    },
  },
  methods: {
    key(e) {
      // console.log(e);
    },
    selectComponent(component){
      this.$store.commit("setChild", {
          data: component.data,
        });

    }
  },

  mounted() {

     
    (this.handelKeyDown = function (e) {
      // console.log(e);
      if (e.keyCode === 40) this.selectedIndex += 1;
      else if (e.keyCode === 38) this.selectedIndex -= 1;
      else if (e.keyCode != 13) this.selectedIndex = 0;
      if (this.selectedIndex < 0)
        this.selectedIndex = this.componentList.length - 1;
      if (this.selectedIndex > this.componentList.length - 1)
        this.selectedIndex = 0;

      if (e.key === "Enter") {
        e.preventDefault();
        this.selectComponent(this.componentList[this.selectedIndex])
        
        // document.removeEventListener("keydown", handelKeyDown);


      }
    }),
      (this._keyListner = this.handelKeyDown.bind(this));
    document.addEventListener("keydown", this._keyListner);

    this.$refs.search.focus()
  },
  activated(){
    // this.$refs.search.focus()
  },

  watch:{
    'showSelector': function(val){
      // if(val)
      // this.$refs.search.focus()
    }
  },
  beforeUnmount() {
    console.log("UNMOUNT");
    
    document.removeEventListener("keydown", this._keyListner);
  },
};
</script>

<style lang="scss" scoped>
#component-search{
    background-color: transparent;
  padding:0.25em 1em;
  font-size:large;
  color:white;

  border: none;

  &:focus {
    outline: none;
  }
}
.selector {

  box-shadow: -5px -5px 8px #353535ce;

  display: flex;
  flex-direction: column;
  position: fixed;
  // left:0;
  // top:0;
  bottom:55px;
  background-color:black;
  width:300px;
  border-radius: 10px 0 0 0 ;

  
  right:0;
  top:25vw;
  // bottom:25vw;
  overflow-y: scroll;

  .item {
    background-color: rgb(46, 46, 46);
    color: silver;
    &.selected {
      background-color: rgb(55, 58, 73);
      color: whilte;
      border-bottom: 2px solid black;
      margin-bottom: 2px;
    }
    display: flex;
    flex-direction: row;
    text-align: left;
    margin: 4px;
    .icon {
      background-color: rgb(44, 42, 51);
      color: #ccc;
      flex: 50px 0 0;
      border: 1px solid black;
      text-align: center;
      padding: 0.2em;
    }
    .title {
      flex: auto 1 1;
      padding: 0.5em;
      border: 1px solid black;
    }
    &:hover {
      cursor: pointer;
      background-color: rgb(55, 58, 73);
      border-bottom: 2px solid black;
      margin-bottom: 2px;
      .icon {
        background-color: rgb(28, 29, 37);
        color: white;
      }
    }
  }
}
</style>