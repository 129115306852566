<template>
    <div>
        {{ data.email }}
  <form>
<div class="form-floating mb-1">
  <input type="name" class="form-control" id="floatingName" placeholder="Your Name" v-model="name">
  <label for="floatingName">Name</label>
</div>
<div class="form-floating mb-1">
  <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" v-model="email">
  <label for="floatingInput">Email address</label>
</div>
<div class="form-floating">
  <textarea class="form-control" placeholder="Your Message" id="floatingTextarea" style="height:25vh"></textarea>
  <label for="floatingTextarea">Message</label>
</div>
<div>
    <div class="btn-group" role="group" aria-label="Basic example">
    <input type="submit" class="btn btn-success" />
    </div>
</div>
  </form>
    </div>
</template>

<script>
export default {
    name: 'contact2',
    props: ['data'],
    data: function(){
        return {
            name: this.data.name ,
            email: this.data.email 
        }
    }
}
</script>

<style>

</style>