<template>
  <div :class="['tk-footer', doEdit ? 'open' : '']">
    <div class="icons">
      <div class="icons-left">
        <div
          class="material-icons"
          @click.stop="$store.state.editMode = !$store.state.editMode"
        >
          edit
        </div>
        <div
          class="material-icons"
          @click.stop="$store.state.showTree = !$store.state.showTree"
        >
          menu
        </div>
        <!-- <div class="material-icons"  @click.stop="newPage">
        folder
      </div>   -->
        <div class="material-icons" @click.stop="savePage">save</div>
      </div>
      <div class="icons-right">
        <div
          class="material-icons"
          @click.stop="deleteElement"
          v-if="$store.state.currentItem"
        >
          delete
        </div>
        <div
          class="material-icons"
          @click.stop="moveUp"
          v-if="$store.state.currentItem"
        >
          arrow_upward
        </div>
        <div
          class="material-icons"
          @click.stop="moveDown"
          v-if="$store.state.currentItem"
        >
          arrow_downward
        </div>
        <div
          class="material-icons"
          @click.stop="addElement"
          :class="[$store.getters.showSelector ? 'select' : '']"
        >
          add
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    newPage() {
      this.$store.commit("newPage");
    },
    savePage() {
      this.$store.dispatch("savePage");
    },
    addElement() {
      if (this.$store.getters.showSelector)
        this.$store.commit("removeChild", {
          child: this.$store.state.currentItem,
        });
      else this.$store.commit("newChild");
    },
    deleteElement() {
      this.$store.commit("removeChild", {
        child: this.$store.state.currentItem,
      });
    },
    moveUp(el) {
      this.$store.commit("moveUp", { child: this.$store.state.currentItem });
    },
    moveDown(el) {
      this.$store.commit("moveDown", { child: this.$store.state.currentItem });
    },
  },
  computed: {
    doEdit() {
      return this.$store.state.editMode;
    },
  },
};
</script>

<style lang="scss" scoped>
.icons {
  width: 100%;
  padding: 0.25em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  .material-icons {
    border: 1px solid rgb(46, 46, 46);
    padding: 0.25em;

    border-radius: 25%;
    font-size: xx-large;
    margin-right: 0.5em;
    &.select {
      background-color: grey;
    }
    &:hover {
      border-color: white;
      background-color: rgb(35, 35, 41);
      cursor: pointer;
    }
  }
  .icons-right, .icons-left{
    flex: auto 0 0;
    display: flex;
    flex-direction: row;
    
  }
  .icons-right{
    
  }
}
.tk-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 60px;
  border-radius: 0 20px 0 0;
  overflow: hidden;
  background-color: black;
  grid-area: f;
  text-align: left;
  color: white;
  transition: 0.2s all;
  z-index: 9999999999999;
  &.open {
    width: 100%;
    border-radius: 0;
  }
}
</style>