<template>
  <div class="editor" v-if="$store.state.editMode">
    <div class="options" v-if="type && type!=='select' && type == 'content'">
      <span class="material-icons" @click="convertToContent"> article </span>
      <span class="material-icons" @click="convertToHTML"> code </span>
      <span class="material-icons"> palette </span>

      <input
        id="nodeTitle"
        v-if="$store.state.currentItem"
        type="text"
        v-model="$store.state.currentItem.id"
        @focus="$event.target.select()"
      />
    </div>
    
    <!-- v-model="$store.state.currentItem.content" -->
    <v-ace-editor
    id="htmlEdit"
    ref="markdownEdit"
    key="markdownEdit"
      v-if="type==='content'"
      v-model="$store.state.currentItem.content"
      lang="markdown"
      theme="chaos"
      style="height: 100%; min-width:calc(45vw - 150px); font-size:small"
    />
    <v-ace-editor
    ref="htmlEdit"
    
      v-if="type==='html'"
      v-model="$store.state.currentItem.html"
      lang="html"
      theme="tomorrow_night_eighties"
      style="height: 100%;min-width:calc(45vw - 150px); font-size:small"
      
    />

    <!-- <div v-if="type==='container'">
      <flow-select></flow-select>
    </div>
    {{type}} -->

    <!-- <textarea v-if="$store.state.currentItem" v-model="content"></textarea> -->
  </div>
</template>

<script>
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/theme-chaos";
import "ace-builds/src-noconflict/theme-tomorrow_night_eighties";
import "ace-builds/src-noconflict/mode-markdown";
import TypeSelect from './TypeSelect.vue';
import FlowSelect from './FlowSelect.vue';

export default {
  components: {
    VAceEditor,
    TypeSelect,
    FlowSelect
  },
  mounted(){
        //     setTimeout( ()=> {
        //   // document.getElementById("htmlEdit").focus();
        //   if(this.$refs['markdownEdit']){
        //     console.log(this.$refs['markdownEdit'])
        //     this.$refs['markdownEdit'].editor.focus()
        //     this.$refs['markdownEdit'].focus()

        //   }
        //   // document.getElementById("htmlEdit").select();
        // }, 200);
  },
  methods: {
    
    aceChange(val) {
      console.log(this.aceContent);
      // this.$store.state.currentItem.content = this.aceContent
    },
    convertToHTML() {
      this.$store.commit("convertToHTML");
    },
    convertToContent(){
      this.$store.commit("convertToContent")
    }
  },
  computed:{
    type(){
      if (!this.$store.state.currentItem) return // Nothing selected
      if (this.$store.state.currentItem.id === "root") return // Root Selected
      if (this.$store.state.editId != null) return // Inline edit
      if ((this.$store.state.currentItem.children ))
        return 'container'
      if (this.$store.state.currentItem.component != null) return 'component'      
      if (this.$store.state.currentItem.html != null) return 'html'
      if (this.$store.state.currentItem.content != null) return 'content'      
      
      return 'select'
    }
  },
  data() {
    return {
      aceContent: null,
      // content: this.$store.state.currentItem.content
    };
  },
};
</script>

<style lang="scss" scoped>
.options {
  display: flex;
}
.editor {
  background-color: rgb(231, 231, 231);
  color: rgb(56, 58, 70);
  // color:white;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  border-top: 1px solid silver;
}
.options input {
  flex: auto 1 1;
  background-color: none;
  background-color: rgba(0, 0, 0, 0);
  color: rgb(56, 58, 70);
  border: none;

  // padding: 0.5em;
  &:focus {
    outline: none;
  }
}
.idname {
  padding-left: 1em;
}
textarea {
  border: 1px solid silver;
  padding: 0.5em;
  width: 100%;

  flex: auto 1 1;
  background-color: black;
  color: white;
}
</style>