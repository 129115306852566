<template>
          <div v-if="!$store.getters.items.children" class="start">
            <div>
          Press <strong>Ctrl + Space</strong> to get started
          <div class="shortcuts">
            <div><strong>Ctrl + S</strong> - Save</div>
            <div><strong>Ctrl + N</strong> - New Document</div>
            <div><strong>Shift + Delete</strong> - Remove selected node</div>
          </div>
            </div>
        </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.start{
    font-size: xx-large;
    text-align: center;
    color:#AAA;
    font-weight: bold;
    align-items: center;
      height:80vh;
    
    flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
    
    .shortcuts{
      flex: auto 1 1;
      font-size: small;
      display:flex;
      flex-wrap: wrap;
      max-width:400px;
      justify-content: center;
      margin: 1em auto;
      
      
      div{
        flex: auto 0 0;
        padding:0 1em;
        // border:1px solid grey;
      }
    }

  }
</style>