<template>

  <div class="tk-builder" :class="$store.state.editId?'editing':''">
    <div class="tk-inspector" v-if="$store.state.showTree && $store.state.editMode">
      <DocTree ></DocTree>
    </div>
    <div class="tk-page">
      <Page></Page>
    </div>
    <div class="tk-editor">
      <editor-content />
    </div>
  </div>

    <type-select v-if="showSelector" />
    <edit-bar />
  


</template>

<script>
import Page from './components/Page.vue'
import DocTree from './components/DocTree.vue'
import EditorContent from './components/EditorContent.vue'
import EditBar from './components/EditBar.vue'
import TypeSelect from './components/TypeSelect.vue'

export default {
  name: 'App',
  components: {
    Page,
    DocTree,
    EditorContent,
    EditBar,
    TypeSelect
    
  },
  data:function(){
    return {
      drawer: false,
    }
  },
  computed:{
        showSelector: function(){
      if (!this.$store.state.currentItem) return false// Nothing selected
      if (this.$store.state.currentItem.id === "root") return false// Root Selected
      if (this.$store.state.editId !== null) return false
      if ((this.$store.state.currentItem.children ))
        return false
      if (this.$store.state.currentItem.html != null) return false
      if (this.$store.state.currentItem.content != null) return false
      if (this.$store.state.currentItem.component != null) return false
      return true
      
    },
  },
    mounted() {
    this._keyListner = function (e) {
      if (e.key === " " && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.$store.state.editMode= true;
        this.$store.commit("newChild");
        // setTimeout(function () {
          //   document.getElementById("nodeTitle").focus();
        //   document.getElementById("nodeTitle").select();
        // }, 100);
      }
          if(!this.$store.state.editMode) return
          // console.log(e);
        if(e.key === "s" && (e.ctrlKey || e.metaKey)){
          e.preventDefault();
          this.$store.dispatch('savePage')
        }
        if(e.key === "n" && (e.ctrlKey || e.metaKey)){
          e.preventDefault();
          this.$store.commit('newPage')
        }
        if((e.key ==='Delete' || e.key === 'Backspace') && (e.shiftKey)) 
          {
            console.log("DELETE NODE")
            this.$store.commit('removeChild', {child:this.$store.state.currentItem})
            this.$store.commit('noEdit')
          }
        
    };
    document.addEventListener("keydown", this._keyListner.bind(this));
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this._keyListner);
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
  height: calc(100vh - 100px);
}

body{
  margin:0;
  padding: 0;
  background-color:#222;
  
}
.tk-builder{
  display: grid;
  grid-template-columns: auto auto 3fr;
  grid-template-rows: 2fr 0fr;
  
  grid-template-areas: "i e p"
                        "f f f"
  ;
                        // "i e";
  height: calc(100vh);
  .editing{
    border-bottom:1px solid black;
  
    grid-template-rows: 2fr 300px;
  }

}

.tk-inspector{
  grid-area: i;
  background-color:#333;
  
  overflow: auto;
  width:300px;
  padding-bottom:60px;
}

.tk-editor{
  grid-area: e;

}
</style>
