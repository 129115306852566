<template>
  <h1 v-if="!$store.state.editMode" v-html="content"></h1>
  <div v-show="$store.state.editMode">
    <!-- <input type="text" v-model="data.content" /> -->
    <h1 ref="editor" contenteditable="true" @input="onInput" @keydown.enter="doneEdit" @blur="onBlur" @focus="onFocus"> </h1>
  </div>

</template>

<script>
import Mustache from "mustache"
export default{
    props: ['data', 'dataSource'],
    watch:{
        editMode:function(v){
            if (v){
            this.$refs.editor.innerText = this.content || ''
            this.$refs.editor.focus()
            }
        }
    },
    mounted(){
            
            this.$refs.editor.innerText = this.content || ''
            this.$refs.editor.focus()
            
    },
    methods:{
        onInput(e)
        {
            const content = e.target.innerText
            this.data.content = content
        },
        onBlur(e){
            this.$refs.editor.innerText = this.content 
        },
        onFocus(e){
            this.$refs.editor.innerText = this.data.content || ''
        },
        doneEdit(e){
            e.target.blur()
        }
    },
    computed:{
        editMode(){
            return this.$store.state.editMode
        },
        content(){
            if(!this.data.content) return ''
            return Mustache.render(this.data.content, this.dataSource) || ''
        }
    }
}
</script>

<style lang="scss" scoped>
input{
    width:100%;
    border: none;
    :focus{
        outline: none;
    }
}
</style>